import 'firebase/auth';
import React, { Component } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, isLoaded } from 'react-redux-firebase';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import { MP_GetUserClaims, MP_UserClaim, MP_UserPortalAccess, MP_UserRegistration, MP_UserRoles } from './components/FirebaseHelper/FirebaseHelper';
import './scss/style.scss';
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Logout = React.lazy(() => import('./views/pages/login/Logout'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const AccountPending = React.lazy(() => import('./views/pages/account-pending/AccountPending'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

function AuthIsLoaded({ children }) {
  const auth = useSelector(state => state.firebase.auth)
  let profile = useSelector(
    ({ firebase: { profile } }) => profile
  );

  console.log("auth changed:");
  console.log(auth);

  if (isLoaded(auth) && !isEmpty(auth) && (!isLoaded(profile) || isEmpty(profile))) {
      return <div></div>
  }
  else if(!isLoaded(auth)) {
    return <div></div>
  }

  return children

}

const PrivateRoute = ({ component: Component = null, render: Render = null, ...rest }) => {
  const claims = MP_GetUserClaims();

  console.log(claims);
  let userRole = claims[MP_UserClaim.role] || null;
  let portalAccess = claims[MP_UserClaim.portalAccess] || null;
  let registrationStatus = claims[MP_UserClaim.registration] || null;

  if (portalAccess !== MP_UserPortalAccess.enabled && userRole !== MP_UserRoles.admin) {
    if (registrationStatus === MP_UserRegistration.pending) {
      //user is registered and pending portal access registration
      return (
        <Route {...rest} render={props => <Redirect to={{ pathname: '/account-pending', state: { from: props.location } }}>
          {console.log("Your Account is Being Reviewed")} </Redirect>} />
      )
    }
    else {

      //this user shouldn't be 

      console.log(`User Role: ${userRole}`);
      if(userRole === null) {
        //go to login
        return (
          <Route {...rest} render={props => <Redirect to={{ pathname: '/login', state: { from: props.location } }}>
            {console.log("redirect to logout")} </Redirect>} />
        )
  
      }
      else {
        return (
          <Route {...rest} render={props => <Redirect to={{ pathname: '/register', state: { from: props.location } }}>
            {console.log("redirect to logout")} </Redirect>} />
        )
  
      }
    }
  }
  else {

    //Authorized
    return (
      <Route
        {...rest}
        render={props =>

          Render ? (
            Render(props)
          ) : Component ? (
            <Component {...props} />
          ) : null
        }
      />
    )
  }
}

class App extends Component {

  render() {

    return (
      <HashRouter>
        <AuthIsLoaded>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
              <Route exact path="/logout" name="Logout" render={props => <Logout {...props} />} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
              <Route exact path="/account-pending" name="Your Account is Being Reviwed" render={props => <AccountPending {...props} />} />
              <PrivateRoute exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
              <PrivateRoute exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
              <PrivateRoute path="/" name="Home" render={props => <TheLayout {...props} />} />
            </Switch>
          </React.Suspense>
        </AuthIsLoaded>
      </HashRouter>
    );
  }
}

export default App;

import { isEmpty, isLoaded, useFirebase } from 'react-redux-firebase'
import {
  geocodeByAddress
} from 'react-places-autocomplete';
import { useSelector } from 'react-redux';
import _ from 'lodash'

const MP_portalMetadataRef = '/portal-metadata';
const MP_adminsRef = '/admins';
const MP_stripeAccountsRef = '/stripe-accounts';
const MP_masajidRef = '/masajid';
const MP_pendingRegistrationRef = '/pending/registration';
const MP_pendingMasjidModerationRef = '/pending/moderation';

const MP_UserClaim = {
  role: "role",
  portalAccess: "portalAccess",
  registration: "registration"
}

const MP_UserRoles = {
  admin: "admin",
  moderator: "moderator",
  masjid: "masjid",
  user: "user"
}

const MP_UserRegistration = {
  pending: "pending",
  active: "active",
  disabled: "disabled"
}

const MP_UserPortalAccess = {
  enabled: "enabled",
  disabled: "disabled",
}

const MP_refValue = (data, ref) => {
  let refDot = ref.substring(1);
   refDot = refDot.replace(/\//g, '.');
  return _.get(data, refDot, undefined);
}

const MP_GetUserClaims = () => {
  let profile = useSelector(
    ({firebase: {profile} }) => profile
  );
  
  console.log("Profile:")
  console.log(profile);
  if(isLoaded(profile) && !isEmpty(profile)) {
    return profile.token.claims;  
  }
  else {
    return {};
  }

}
const MP_GetUserRole = () => {

  let claims = MP_GetUserClaims();
  return (claims || {}).role || MP_UserRoles.user;
}

const MP_DictForAddressBlock = async (addressBlock) => {

  let result = await geocodeByAddress(addressBlock);
  if (result.count === 0) {
    alert("Address is Invalid");
    return null;
  }


  let streetNumber = "";
  let route = "";
  let locality = "";
  let administrative_area_level_1 = ""
  let postal_code = "";

  let addressComponents = result[0].address_components;

  addressComponents.forEach(component => {
    if (component.types.includes("street_number")) {
      streetNumber = component.short_name;
    }
    else if (component.types.includes("route")) {
      route = component.short_name;
    }
    else if (component.types.includes("locality")) {
      locality = component.short_name;
    }
    else if (component.types.includes("administrative_area_level_1")) {
      administrative_area_level_1 = component.short_name;
    }
    else if (component.types.includes("postal_code")) {
      postal_code = component.short_name
    }
  });

  console.log(addressComponents);

  let addressDict = {
    address: {
      street: streetNumber + " " + route,
      locality: locality + ", " + administrative_area_level_1 + " " + postal_code
    },
    location: {
      lat: result[0].geometry.location.lat(),
      lon: result[0].geometry.location.lng()
    }
  }

  return addressDict;

}


/**
Stripe Payments
**/

const MP_GetAccountOnboardingLink = (masjidId) => {
  const firebase = useFirebase();
  let callable = firebase.functions().httpsCallable("getAccountOnboardingLink");

  callable({masjidId: masjidId}).then((result) => {
      console.log('onboardingLink:');
      console.log(result);
      let onboardingUrl = result.data.onboardingUrl
      window.location.href = onboardingUrl;

   }).catch((error) => {
     console.log(error);
    });
}

//**  **//
const MP_GetAllUsers = () => {
  const firebase = useFirebase()
  return firebase.functions().httpsCallable("getAllUsers").call({});
}

const MP_UpdateUserRole = () => {
  return useFirebase().functions().httpsCallable("setUserRole");
}

function MP_UpdateAnnouncementStatus() {
  const firebase = useFirebase();
  let callable = firebase.functions().httpsCallable(("setAnnouncementStatus"));
  return callable;
}

  /**
   * Retrieves Announcements for Moderation
   *
   * @param page The name of the https callable function.
   * @param pageSize The options for this HttpsCallable instance.
   * @param status
   * @param query
   * @return The a Promise instance.
   */
function MP_GetAllAnnouncements(page, pageSize, status, query) {

    const firebase = useFirebase();
     let callable = firebase.functions().httpsCallable("allAnnouncements")
     return callable({
       page:page || 0,
       pageSize: pageSize || 100,
       status: status,
       query: query
     });
   }

function MP_GetAllMasajid(pageSize = 100, page = 0, query = "", verified = null, suggested = null) {
  let firebase = useFirebase();
  let callable = firebase.functions().httpsCallable("allMasajid");
  return callable({"pageSize":pageSize, "page":page, "query":query, "verified":verified, "suggested":suggested});
}

function MP_SearchMasajid(query, location = null) {
  let firebase = useFirebase();
  let callable = firebase.functions().httpsCallable("searchMasajid");
  return callable({"query":query, "location": location});
}

function MP_RequestAdminRights(firebase, masjidId) {
  //let firebase = useFirebase();
  let callable = firebase.functions().httpsCallable("requestMasjidAdminRights");
  return callable({"masjidId":masjidId});
}

function MP_ApproveAdminRights(firebase, userId, masjidId) {
  let callable = firebase.functions().httpsCallable("approveMasjidAdminRights");
  console.log("masjidId: " + masjidId + " userId: " + userId)
  return callable({"masjidId":masjidId, userId: userId});
}

function MP_RejectAdminRights(firebase, userId, masjidId) {
  let callable = firebase.functions().httpsCallable("rejectMasjidAdminRights");
  console.log("masjidId: " + masjidId + " userId: " + userId)
  return callable({"masjidId":masjidId, userId: userId});
}

function MP_GetPendingRegistrations(firebase) {
  let callable = firebase.functions().httpsCallable("getPendingRegistrations");
  return callable();
}

/*
 * Allowable actions:  approve, reject
 */
function MP_UpdatePendingRegistration(firebase, action, userId, masjidId=null) {
  let callable = firebase.functions().httpsCallable("updatePendingRegistrations");
  return callable({action:action, userId:userId, masjidId:masjidId});
}

function MP_AddMasjidAdmin(masjidId, adminEmail) {

  let firebase = useFirebase();
  let callable = firebase.functions().httpsCallable("addMasjidAdmin");
  callable({masjidId: masjidId, email: adminEmail}).then((result) => {
     console.log('updated:');
     console.log(result);
   }).catch((error) => {
     console.log(error);
    });

   return;
}

function MP_RemoveMasjidAdmin(masjidId, adminId) {
  let firebase = useFirebase();
  let callable = firebase.functions().httpsCallable("removeMasjidAdmin");
  callable({masjidId: masjidId, adminId: adminId}).then((result) => {
     console.log('admin removed:');
     console.log(result);
   }).catch((error) => {
     console.log(error);
    });

   return;

}

export {
  MP_refValue,
  MP_masajidRef,
  MP_adminsRef,
  MP_stripeAccountsRef,
  MP_pendingRegistrationRef,
  MP_pendingMasjidModerationRef,
  MP_portalMetadataRef,
  MP_UserClaim,
  MP_UserPortalAccess,
  MP_UserRoles,
  MP_UserRegistration,
  MP_GetUserClaims,
  MP_GetUserRole,
  MP_DictForAddressBlock,
  MP_GetAllAnnouncements,
  MP_GetAllUsers,
  MP_UpdateUserRole,
  MP_UpdateAnnouncementStatus,
  MP_GetAllMasajid,
  MP_SearchMasajid,
  MP_RequestAdminRights,
  MP_ApproveAdminRights,
  MP_RejectAdminRights,
  MP_GetPendingRegistrations,
  MP_UpdatePendingRegistration,
  MP_AddMasjidAdmin,
  MP_RemoveMasjidAdmin,
  MP_GetAccountOnboardingLink
}


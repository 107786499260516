import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import App from './App';
//import Firebase, {FirebaseContext} from './components/Firebase';

import * as serviceWorker from './serviceWorker';
import { icons } from './assets/icons'
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'
import 'firebase/functions' // <- needed if using httpsCallable
import { createStore, combineReducers } from 'redux'
import {
  ReactReduxFirebaseProvider,
  firebaseReducer,
} from 'react-redux-firebase'

React.icons = icons

const fbConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
}

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'profile',
  enableClaims: true
  // useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
}

// Initialize firebase instance
firebase.initializeApp(fbConfig)
console.log('firebase initialized');

// Initialize other services on firebase instance
firebase.functions() // <- needed if using httpsCallable
function mpReducer(state = {}, { type, ...rest }) {
  switch (type) {
    case 'set':
      console.log(state);
      return { ...state, ...rest }
    default:
      return state
  }
}

// Add firebase to reducers
const rootReducer = combineReducers({
  masjidpro: mpReducer,
  firebase: firebaseReducer
})
const initialState = {}
const store = createStore(rootReducer, initialState)

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch
  // createFirestoreInstance // <- needed if using firestore
}

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <App />
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
